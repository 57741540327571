import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from '../../constants/constants';
import { UtilsService } from '../../services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class RootLoadGuard {
  constructor(
    private utils: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.utils.getUserInfo() && !location.href.includes('code=')) {
      this.router.navigate([Constants.routes.login], {
        queryParams: { redirectURL: location.href.replace(location.origin, '') }
      });
      return false;
    } else {
      return true;
    }
  }
}
